<!-- reference: https://github.com/DHTMLX/vue-suite-demo/blob/master/src/components/tabbar/TabbarBase.vue -->

<template>
  <div>
    
    <div style="display: flex; justify-content: center;"><h1>Producing...</h1></div>
    <div style="display: flex; justify-content: center;" id="holder"></div>

    <div class="parent">
      <button @click="sendMessage('reset')" class="dhx_button dhx_button--color_primary dhx_button--view_flat custom-button child">
        <span class="dhx_button__icon dxi dxi-plus"></span>
        <span class="dhx_button__text">Reset</span>
      </button>

      <button
        @click="sendMessage('start')" class="dhx_button dhx_button--color_success dhx_button--view_flat custom-button child">
        <span class="dhx_button__icon dxi dxi-plus"></span>
        <span class="dhx_button__text">Start</span>
      </button>

      <button
        @click="sendMessage('stop')" class="dhx_button dhx_button--color_danger dhx_button--view_flat custom-button child">
        <span class="dhx_button__icon dxi dxi-plus"></span>
        <span class="dhx_button__text">Stop</span>
      </button>
    </div>

    
  </div>
</template>

<style scoped>
h1 {
  font-family: Arial;
}
.custom-button {
  width: 100px;
  height: 100px;

  border-radius: 10px;
}

.parent {
  /*border: 1px solid black;  */
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.parent .child {
  margin: auto;
}

.child .largeicon {
  font-size: 50px;
}
</style>

<script>
import {
  alert as dhxAlert,
  confirm as dhxConfirm,
  message as dhxMessage,
} from "@/dhx-suite/codebase/suite.min.js";
import Raphael from "@/raphael.min.js";

export default {
  name: "MessageBase",
  data: () => ({
    slider: null,
    tabbar: null,
    r: null,
    R: null,
    init: true,
    sec: null,
    min: null,
    param: null,
    paramFat: null,
    HOST: null,
    ws: null,
    config: null,
  }),
  mounted() {





    this.r = Raphael("holder", 300, 300);

    this.R = 200;
    this.init = true;

    // Custom Attribute
    this.r.customAttributes.arc = function (value, total, R) {
      var alpha = (360 / total) * value,
        a = ((90 - alpha) * Math.PI) / 180,
        x = 150 + R * Math.cos(a),
        y = 150 - R * Math.sin(a),
        //color = "hsb(".concat(Math.round(R) / 200, ",", "1.0", ", .75)"),
        color = "#ff8000",
        path;
      if (total == value) {
        path = [
          ["M", 150, 150 - R],
          ["A", R, R, 0, 1, 1, 150, 150 - R],
        ];
      } else {
        path = [
          ["M", 150, 150 - R],
          ["A", R, R, 0, +(alpha > 180), 1, x, y],
        ];
      }
      return { path: path, stroke: color };
    };

    this.sec = this.r
      .path()
      .attr({ stroke: "#fff", "stroke-width": 30 })
      .attr({ arc: [0, 60, this.R] });
    this.min = this.r
      .path()
      .attr({ stroke: "#fff", "stroke-width": 80 })
      .attr({ arc: [0, 60, this.R] });


    this.HOST = "wss://herokuwebsocket1.herokuapp.com/";
    this.ws = new WebSocket(this.HOST);

    this.ws.onmessage = (event) => {
      //el = document.getElementById('server-time');
      //el.innerHTML = 'Server time: ' + event.data;

      console.log(event.data);
      //var d = new Date;

      this.updateVal(event.data, 150, 40, this.min, 4);
      this.updateVal(event.data, 60, 100, this.sec, 2);
    };
  },
  beforeDestroy() {},

  methods: {
    showAlert() {
      dhxAlert({
        header: "DHTMLX Alert Box",
        text: "With the help of DHTMLX components you are able to create versatile user interfaces for web apps of any kind. Modern technologies and design make our library a great tool for developers working on the most complex projects. And here dhtmlxMessage represents a small but indispensable toolkit of helpers for initializing all types of messages: notifications, alert and confirmation boxes, and tooltips. Try out the abilities of dhtmlxMessage in our interactive demos and samples.",
        buttonsAlignment: "center",
        buttons: ["ok"],
      });
    },
    showConfirm() {
      dhxConfirm({
        header: "DHTMLX Confirm Box",
        text: "With the help of DHTMLX components you are able to create versatile user interfaces for web apps of any kind. Modern technologies and design make our library a great tool for developers working on the most complex projects. And here dhtmlxMessage represents a small but indispensable toolkit of helpers for initializing all types of messages: notifications, alert and confirmation boxes, and tooltips. Try out the abilities of dhtmlxMessage in our interactive demos and samples.",
        buttons: ["Cancel", "Start"],
        buttonsAlignment: "center",
      });
    },
    showMessage() {
      dhxMessage({
        text: "Here is dhtmlxMessage",
        icon: "dxi-close",
        expire: 3000,
      });
    },
    sendMessage(msg) {
      this.ws.send(msg);
    },

    updateVal(value, total, R, hand, id) {
      //var color = "hsb(".concat(Math.round(R) / 200, ",", value / total, ", .75)");
      //var color = "hsb(".concat(Math.round(R) / 200, ",", "1.0", ", .75)");
      var color = "#ff0000";

      if (this.init) {
        hand.animate({ arc: [value, total, R] }, 20, ">");
      } else {
        if (!value || value == total) {
          value = total;
          hand.animate(
            { arc: [value, total, R] },
            750,
            "easeInOut",
            function () {
              hand.attr({ arc: [0, total, R] });
            }
          );
        } else {
          hand.animate({ arc: [value, total, R] }, 750, "<>");
        }
      }
      //html[id].innerHTML = (value < 10 ? "0" : "") + value;
      //html[id].style.color = Raphael.getRGB(color).hex;
    },
  },
};
</script>