import Vue from 'vue'
import Router from 'vue-router'

// Page content
import dhx_dataview from '@/components/dhx-dataview'
import Machine from '@/components/machine'
import Page1 from '@/components/Page1'
import Home from '@/components/Home'

import MachineStatus from '@/components/machine.status'
import MachineControl from '@/components/machine.control'

// Fallback page
import PageNotFound from '@/components/PageNotFound'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/page-1',
      name: 'Page 1',
      component: Page1
    },
    {
      path: '/machine',
      name: 'DHTMLX',
      component: Machine,
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: '/',
          component: MachineStatus
        },
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: 'status',
          component: MachineStatus
        },
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: 'control',
          component: MachineControl
        },
      ]
    },
    {
      path: '/dataview',
      name: 'dhx-dw',
      component: dhx_dataview
    },
    {
      path: '**',
      name: 'PageNotFound',
      component: PageNotFound
    }
  ]
})
