// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js!./dhx-suite/codebase/suite.min.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js!../static/kpcs_style.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../node_modules/css-loader/dist/cjs.js!./mdi/css/materialdesignicons.min.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
exports.push([module.id, "\n.custom-class .dhx_tabbar-tab-button {\n\t\t/*background-color: #ff8000;*/\n  \tcolor: #0a0a0a;\n}\n.custom-class .dhx_tabbar-tab-button--active {\n\t\t/*background-color: #ff8000;*/\n  \tcolor: #0a0a0a;\n}\n.custom-class .dhx_tabbar-header-active {\n\t\tbackground-color: #ff8000;\n}\n", ""]);
// Exports
module.exports = exports;
