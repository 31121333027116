<template>
  <div>
    <div id="pbar" class="noAnim" style="width: 50%; height: 2px; background-color: orange"></div>
    <div ref="list"></div>
    <div style="text-align: center; color: rgba(0,0,0,.3); margin-top: 5px; ">
      <i class="dhx_list-item--text">
        Last updated <span id="lastupdate"></span>
        <br />version 0.3
      </i>
    </div>
  </div>
</template>

<style>
.animatedTrans {
  transition-delay: 0s;
  transition-duration: 10s;
  transition-property: width;
  transition-timing-function: linear;
}
.noAnim {
  transition-delay: 0s;
  transition-duration: 1s;
  transition-property: none;
  transition-timing-function: linear;
}
</style>

<script>
import router from './../router'

import { List as ListDHX } from "@/dhx-suite/codebase/suite.min.js";

export default {
  name: "ListBase",
  data: () => ({
    events: [],
    list: null,
  }),
  mounted() {

    var dataset = [
        {"id":"1", "title": "C64", "short": "producing copy 5 of 10", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"2", "title": "C60", "short": "producing copy 1 of 1", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"3", "title": "C64 stacker", "short": "producing copy 193 of 200", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"4", "title": "C66 robot", "short": "producing copy 237 of 5000", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"5", "title": "C64", "short": "producing copy 5 of 10", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"6", "title": "C60", "short": "producing copy 1 of 1", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"7", "title": "C64 stacker", "short": "producing copy 193 of 200", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"8", "title": "C66 robot", "short": "producing copy 237 of 5000", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"9", "title": "C64", "short": "producing copy 5 of 10", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"10", "title": "C60", "short": "producing copy 1 of 1", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"11", "title": "C64 stacker", "short": "producing copy 193 of 200", "img": "/gfx/output-onlinepngtools.png"},
        {"id":"12", "title": "C66 robot", "short": "producing copy 237 of 5000", "img": "/gfx/output-onlinepngtools.png"},
    ];

    dataset = [{"id":"1","title":"24 - Idle","short":"2023-07-17 16:26:30","img":"/gfx/table5.png"},{"id":"2","title":"44 - Busy","short":"2023-07-17 16:26:30","img":"/gfx/table5.png"},{"id":"3","title":"64 - Busy","short":"2023-07-17 16:26:33","img":"/gfx/table3.png"}];
  
    function returnStatusImage(state) {
      if (state === "Running") {
        return "/Start.svg";
      } else if (state === "Offline") {
        return "/Offline.svg";
      } else {
        return "/Pause.svg";
      }
    }

    this.list = new ListDHX(this.$refs.list, {
      css: "dhx_widget--bordered dhx_widget--no-border_bottom dhx_widget--bg_white",
      template: item => `
      <div style="display:flex; flex-direction: row; align-items: center;">
        <img style="width: 50px; margin-right: 15px;" src="${(returnStatusImage(item.running))}" />
        <span style="flex: 1;"><strong>${item.title}</strong>&nbsp;-&nbsp;<b>${item.running}</b><br />
        Status: ${item.status}&nbsp;(${item.paused})<br/>
        Speed: ${item.speed}<br/>
        S/E: ${item.safety} / ${item.error}<br/>
        <!--<i>Last updated ${(this.setLastupdate(item.short))}<br />version 0.3</i>--></span><br/>
        <span class="mdi mdi-chevron-right-circle" style="font-size: 30px;"></span>
      </div>`,
      //height: 400,

      // cutout from template parameter
      // <img src="${item.img}" />
    });

    //this.list.data.load("/status.json");
    ////this.list.data.parse(dataset);

    this.updateData();

    this.list.events.on("click", id => {
      const keyId = Math.random();
      console.log([{ keyId, name: "click", value: id }]);
      //router.push("/machine");
      //tabbar.setActive("page-1");
    });
  },
  methods: {
    updateData() {
      this.list.data.removeAll();
      this.list.data.load("/status.json?id=" + self.crypto.randomUUID());

      document.getElementById("pbar").className = "noAnim";
      document.getElementById("pbar").style.width = "100%";

      setTimeout(() => {
        document.getElementById("pbar").className = "animatedTrans";
        document.getElementById("pbar").style.width = "0%";
      }, 100);

      setTimeout(() => {
        this.updateData();
      }, 10100);
    },

    setLastupdate(msg) {
      document.getElementById("lastupdate").innerText = msg;
      return msg;
    }
  },
  beforeDestroy() {
    if (this.list) {
      this.list.destructor();
    }
  },
};
</script>
