<!-- reference: https://github.com/DHTMLX/vue-suite-demo/blob/master/src/components/tabbar/TabbarBase.vue -->

<template>
  <div>

    <router-view></router-view>

    <div ref="tabbar" class="dhx-container--tabbar" style="width: 100%; position: fixed; bottom: 0px;"></div>
  </div>
</template>

<style scoped>
  .custom-button {
    width: 100px;
    height: 100px;

    border-radius: 10px;
  }

  .parent {
    /*border: 1px solid black;  */
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }

  .parent .child {
    margin: auto;
  }

  .child .largeicon {
    font-size: 50px;
  }

</style>

<script>
import router from './../router'

import { Slider as SliderDHX } from "@/dhx-suite/codebase/suite.min.js";
import { Tabbar as TabbarDHX } from "@/dhx-suite/codebase/suite.min.js";

//var tabbar;

import {
  alert as dhxAlert,
  confirm as dhxConfirm,
  message as dhxMessage,
} from "@/dhx-suite/codebase/suite.min.js";

export default {
  name: "MessageBase",
  data: () => ({
    slider: null,
    tabbar: null,
  }),
  mounted() {
    this.slider = new SliderDHX(this.$refs.slider, {});

        this.tabbar = new TabbarDHX(this.$refs.tabbar, {
      mode: "bottom",
      css: " dhx_widget--bg_white custom-class",
      views: [
        {
          id: "/machine",
          tab: "Status",
          tabCss: "custom-class",
          html: "Window 1",
        },
        {
          id: "/machine/",
          tab: "Queue",
          html: "Window 2",
        },
        {
          id: "/machine/control/",
          tab: "Control",
          html: "Window 3",
        },
        {
          id: "/",
          tab: "Main",
          html: "Window 4",
        }
      ],
    });

    this.tabbar.events.on("Change", function(id, prev){
      router.push(id);

    });
  },
  beforeDestroy() {
    if (this.slider) {
      this.slider.destructor();
    }
    
    if (this.tabbar) {
      this.tabbar.destructor();
    }
  },

  methods: {
    showAlert() {
      dhxAlert({
        header: "DHTMLX Alert Box",
        text:
          "With the help of DHTMLX components you are able to create versatile user interfaces for web apps of any kind. Modern technologies and design make our library a great tool for developers working on the most complex projects. And here dhtmlxMessage represents a small but indispensable toolkit of helpers for initializing all types of messages: notifications, alert and confirmation boxes, and tooltips. Try out the abilities of dhtmlxMessage in our interactive demos and samples.",
        buttonsAlignment: "center",
        buttons: ["ok"],
      });
    },
    showConfirm() {
      dhxConfirm({
        header: "DHTMLX Confirm Box",
        text:
          "With the help of DHTMLX components you are able to create versatile user interfaces for web apps of any kind. Modern technologies and design make our library a great tool for developers working on the most complex projects. And here dhtmlxMessage represents a small but indispensable toolkit of helpers for initializing all types of messages: notifications, alert and confirmation boxes, and tooltips. Try out the abilities of dhtmlxMessage in our interactive demos and samples.",
        buttonsAlignment: "center",
      });
    },
    showMessage() {
      dhxMessage({
        text: "Here is dhtmlxMessage",
        icon: "dxi-close",
        expire: 3000,
      });
    },
  },
};
</script>