<!-- reference: https://github.com/DHTMLX/vue-suite-demo/blob/master/src/components/tabbar/TabbarBase.vue -->

<template>
  <div>

    <div class="parent">
      <button @click="showAlert()" class="dhx_button dhx_button--color_primary dhx_button--view_flat custom-button child">
          <span class="dhx_button__icon dxi dxi-plus"></span>
          <span class="dhx_button__text">Reset</span>
      </button>

      <button @click="showMessage()" class="dhx_button dhx_button--color_danger dhx_button--view_flat custom-button child">
          <span class="dhx_button__icon dxi dxi-plus"></span>
          <span class="dhx_button__text">Stop</span>
      </button>

      <button @click="showConfirm()" class="dhx_button dhx_button--color_success dhx_button--view_flat custom-button child">
          <span class="dhx_button__icon dxi dxi-plus"></span>
          <span class="dhx_button__text">Start</span>
      </button>
    </div>

    <div class="parent">
      <button @click="showAlert()" class="dhx_button dhx_button--color_secondary dhx_button--view_flat custom-button child">
          <span class="dhx_button__icon mdi mdi-chevron-double-up largeicon"></span>
      </button>
    </div>

    <div class="parent">
      <button @click="showConfirm()" class="dhx_button dhx_button--color_secondary dhx_button--view_flat custom-button child">
          <span class="dhx_button__icon mdi mdi-chevron-double-left largeicon"></span>
      </button>

      <button @click="showMessage()" class="dhx_button dhx_button--color_secondary dhx_button--view_flat custom-button child">
          <span class="dhx_button__icon mdi mdi-chevron-double-right largeicon"></span>
      </button>
    </div>

    <div class="parent">
      <button @click="showMessage()" class="dhx_button dhx_button--color_secondary dhx_button--view_flat custom-button child">
          <span class="dhx_button__icon mdi mdi-chevron-double-down largeicon"></span>
      </button>
    </div>

  </div>
</template>

<style scoped>
  .custom-button {
    width: 100px;
    height: 100px;

    border-radius: 10px;
  }

  .parent {
    /*border: 1px solid black;  */
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }

  .parent .child {
    margin: auto;
  }

  .child .largeicon {
    font-size: 50px;
  }

</style>

<script>
import { Tabbar as TabbarDHX } from "@/dhx-suite/codebase/suite.min.js";

//var tabbar;

import {
  alert as dhxAlert,
  confirm as dhxConfirm,
  message as dhxMessage,
} from "@/dhx-suite/codebase/suite.min.js";

export default {
  name: "MessageBase",
  data: () => ({
    slider: null,
    tabbar: null,
  }),
  mounted() {

  },
  beforeDestroy() {
  },

  methods: {
    showAlert() {
      dhxAlert({
        header: "DHTMLX Alert Box",
        text:
          "With the help of DHTMLX components you are able to create versatile user interfaces for web apps of any kind. Modern technologies and design make our library a great tool for developers working on the most complex projects. And here dhtmlxMessage represents a small but indispensable toolkit of helpers for initializing all types of messages: notifications, alert and confirmation boxes, and tooltips. Try out the abilities of dhtmlxMessage in our interactive demos and samples.",
        buttonsAlignment: "center",
        buttons: ["ok"],
      });
    },
    showConfirm() {
      dhxConfirm({
        header: "DHTMLX Confirm Box",
        text:
          "With the help of DHTMLX components you are able to create versatile user interfaces for web apps of any kind. Modern technologies and design make our library a great tool for developers working on the most complex projects. And here dhtmlxMessage represents a small but indispensable toolkit of helpers for initializing all types of messages: notifications, alert and confirmation boxes, and tooltips. Try out the abilities of dhtmlxMessage in our interactive demos and samples.",
        buttonsAlignment: "center",
      });
    },
    showMessage() {
      dhxMessage({
        text: "Here is dhtmlxMessage",
        icon: "dxi-close",
        expire: 3000,
      });
    },
  },
};
</script>