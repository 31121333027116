<template>
  <div class="dhx-container--button">
    <p>Connect with:</p>
    <div ref="dataview" style="height: 400px; padding: 0 5px"></div>
  </div>
</template>

<script>
import { DataView as DataviewDHX } from "@/dhx-suite/codebase/suite.min.js";

export default {
  name: "DataviewConfigurated",
  data: () => ({
    dataview: null,
    renderTemplate: item => `
      <div class="template template__container">
        <img
          class="template__image"
          src="https://dhtmlx.github.io/react-widgets/static/${item.img}"
        />
        <h2 class="template__title">${item.title}</h2>
        <p class="template__description">${item.short}</з>
      </div>
    `,
    
  }),
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.dataview = new DataviewDHX(this.$refs.dataview, {
      //...this.options,
      css: "dhx_widget--bordered dhx_widget--bg_white",
      template: this.renderTemplate,
      itemsInRow: 3,
      gap: 10,
      dragMode:"both",
      //editable:true,
      selection: false,
    });

    this.dataview.data.load("/dataview.json");

    this.dataview.events.on("beforeSelect", id => {
      //const keyId = Math.random();
      console.log(Math.random());
      //this.events = [{ keyId, name: "beforeSelect", value: id }].concat(this.events);

      return false;
    });

    this.dataview.events.on("beforeEditStart", id => {
      //const keyId = Math.random();
      console.log(Math.random());
      //this.events = [{ keyId, name: "beforeSelect", value: id }].concat(this.events);

      return false;
    });

        this.dataview.events.on("itemRightClick", id => {
      //const keyId = Math.random();
      console.log(Math.random());
      //this.events = [{ keyId, name: "beforeSelect", value: id }].concat(this.events);

      return false;
    });
  },
  beforeDestroy() {
    if (this.dataview) {
      this.dataview.destructor();
    }
  },
};
</script>