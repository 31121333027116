<template>
<!--
    <ul>
      <li v-for="(link, index) in links" :key="index">
        <router-link :to="link.to">{{ link.name }}</router-link>
      </li>
    </ul>
-->

  <div style="height: 100%; display: flex; flex-direction: column;">
    <div style="flex: 0 0 75px; " ref="toolbar" class="dhx-container--tabbar"></div>
    <div style="flex 1 0 auto; overflow: auto">
        <!--<main>-->
          <router-view />
        <!--</main>-->
    </div>

  </div>
</template>

<style>
  @import 'dhx-suite/codebase/suite.min.css';
  @import '../static/kpcs_style.css';
  @import 'mdi/css/materialdesignicons.min.css';


  .custom-class .dhx_tabbar-tab-button {
		/*background-color: #ff8000;*/
  	color: #0a0a0a;
	}
  .custom-class .dhx_tabbar-tab-button--active {
		/*background-color: #ff8000;*/
  	color: #0a0a0a;
	}

	.custom-class .dhx_tabbar-header-active {
		background-color: #ff8000;
	}
</style>

<style scoped>
  ul {
    list-style: none;
    display: flex;
    padding: 0;
  }

  li {
    padding: 5px 15px 5px 0;
  }

  li a {
    text-decoration: none;
    color: black;
  }

  li a:hover {
    color: #404040;
  }



</style>


<script>
import router from './router'

//import { tabbar } from "./router"
//var tabbar, toolbar;    // const?

//import dhx from "@/dhx-suite/codebase/suite.js";

import { Toolbar as ToolbarDHX } from "@/dhx-suite/codebase/suite.min.js";
import {
  alert as dhxAlert,
  confirm as dhxConfirm,
  message as dhxMessage,
} from "@/dhx-suite/codebase/suite.min.js";


export default {
  data: () => ({
    toolbar: null,

/*    links: [
      {
        name: 'Home',
        to: '/'
      },
      {
        name: 'Page 1',
        to: '/page-1'
      },
      {
        name: 'dHTMLx',
        to: '/dhtmlx'
      },
      {
        name: 'Dataview',
        to: '/dataview'
      },
      {
        name: 'Bad Link',
        to: '/random-bad-url'
      }
    ]
    */
  }),
  mounted() {


    this.toolbar = new ToolbarDHX(this.$refs.toolbar, {
        css: "toolbar_template_a"
    });
    // loading data into Toolbar
    this.toolbar.data.parse(toolbarData);

    this.toolbar.events.on("click", id => {

      if (id == "back") {
        router.push("/");
      } else if (id == "settings") {
        //router.push("page-1");
      } else if (id == "avatar") {
        this.showAlert("001100<br />010010<br />011110<br />100001<br />101101<br />110011");
      } else {
        //this.showAlert();
      };

      

     

      //tabbar.setActive("page-1");
    });

  },
  beforeDestroy() {

  },

  methods: {
    showAlert(msg) {
      dhxAlert({
        header: "K-Connect",
        text: msg,
        buttonsAlignment: "center",
        buttons: ["ok"],
      });
    },
  }
};






	const toolbarData = [
        {
            id: "back",
            type: "button",
            circle: true,
            value: "",
            size: "small",
            icon: "mdi mdi-chevron-left",
            color: "secondary",
            full: true
        },
        {
            type: "spacer"
        },
        {
            id: "language",
            icon: "mdi mdi-earth",
            type: "button",
            view: "link",
            color: "secondary",
            circle: true,
        },
        {
            id: "settings",
            icon: "mdi mdi-cog",
            type: "button",
            view: "link",
            color: "secondary",
            circle: true,
        },
        {
            id: "info",
            icon: "mdi mdi-information-outline",
            type: "button",
            view: "link",
            color: "secondary",
            circle: true,
        },
        {
            id: "avatar",
            type: "imageButton",
            //src: "/avatar.jpg"
            src: "/maskable_icon_x192.png"
        }
    ];
</script>
